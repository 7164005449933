<template>
  <!-- 关键词 文章列表 -->
  <div class="container">
    <!-- 导航 -->
    <V-ArticleNav class="headClass" :navList="navList" type="1"></V-ArticleNav>
    <div class="gWbc title">
      {{detailDat.taskname}}
      <div class="title_div">
        <span>发布者：<span>{{detailDat.username}}</span></span>
        <span style="margin-left:80px;">时间：<span>{{dateType(detailDat.issueTime).split(' ')[0]}}</span></span>
        <span style="margin-left:80px;">任务类型：<span>{{taskShowType()}}</span></span>
      </div>
    </div>
    <div class="detai_main gWbc">
      <p class="miain_p">
        <span>任务描述：</span>
        {{detailDat.describe}}
      </p>
      <!-- <p class="miain_p" style="margin-top:40px;">
        <span>负责人：</span>{{fzrName||'待定'}}
      </p> -->
      <template v-if="type==0">
        <p class="miain_p">
          <span>扫描件：</span>
          <span style="color:#0084FF;text-decoration: underline;cursor: pointer;" 
          @click="tapDownload(detailDat.zipPath)">{{cuPath(detailDat.zipPath)}}</span>
        </p>
        <p class="miain_p">
          <span>著录项：</span>
          {{detailDat.customEntry}}
        </p>
        <p class="miain_p" v-if="detailDat.processingFiles">
          <span>数字化加工文件：</span>
          {{detailDat.processingFiles}}
        </p>
      </template>

      <!-- 数字产品加工 -->
      <template v-if="type==1">
        <p class="miain_p">
          <span>{{cuName(detailDat.type)}}</span>
          {{detailDat.title?'：'+detailDat.title:'无'}}
        </p>
        <p class="miain_p" v-if="detailDat.type=='1'||detailDat.type=='3'">
          <span>关键词：</span>
          {{detailDat.keyword||'无'}}
        </p>
        <p class="miain_p" v-if="detailDat.type=='1'||detailDat.type=='2'||detailDat.type=='3'">
          <span>摘要：</span>
          {{detailDat.summary||'无'}}
        </p>
        <p class="miain_p" v-if="detailDat.type=='1'">
          <span>论文分类号：</span>
          {{detailDat.classification||'无'}}
        </p>
        <p class="miain_p" v-if="detailDat.type=='1'||detailDat.type=='2'||detailDat.type=='3'">
          <span>领域：</span>
          {{detailDat.subject||'无'}}
        </p>
        <p class="miain_p" v-if="detailDat.type=='4'">
          <span>广告涉及产品：</span>
          {{detailDat.product||'无'}}
        </p>
        <p class="miain_p" v-if="detailDat.type=='4'">
          <span>广告风格要求：</span>
          {{detailDat.style||'无'}}
        </p>
        <p class="miain_p" v-if="detailDat.type=='4'">
          <span>广告文案要求：</span>
          {{detailDat.textRequirement||'无'}}
        </p>
        <p class="miain_p" v-if="detailDat.type=='4'">
          <span>广告图片要求：</span>
          {{detailDat.imageRequirement||'无'}}
        </p>
        <p class="miain_p" v-if="detailDat.type=='5'">
          <span>系统功能要求：</span>
          {{detailDat.requirement||'无'}}
        </p>
        <p class="miain_p" v-if="detailDat.type=='5'">
          <span>编程语言：</span>
          {{detailDat.language||'无'}}
        </p>
        <p class="miain_p" v-if="detailDat.type=='5'">
          <span>编程框架：</span>
          {{detailDat.framwork||'无'}}
        </p>
        <p class="miain_p" v-if="detailDat.type=='5'">
          <span>数据库及运行环境：</span>
          {{detailDat.environment||'无'}}
        </p>
        <p class="miain_p" v-if="detailDat.type=='6'">
          <span>包含元素要求：</span>
          {{detailDat.requirement||'无'}}
        </p>
        <p class="miain_p" v-if="detailDat.type=='6'">
          <span>颜色要求：</span>
          {{detailDat.color||'无'}}
        </p>
        <p class="miain_p" v-if="detailDat.type=='6'">
          <span>文字要求：</span>
          {{detailDat.textRequirement||'无'}}
        </p>
      </template>

      <!-- 词条加工 -->
      <!-- <template v-if="type==2"> -->
        <p class="miain_p" v-if="type==2">
          <span>词条领域：</span>
          {{detailDat.type||'无'}}
        </p>
        <!-- <p class="miain_p" >
          <span>附件路径：</span>
          <span style="color:#0084FF;text-decoration: underline;cursor: pointer;" 
          @click="tapDownload(detailDat.fujianPath)">{{cuPath(detailDat.fujianPath)}}</span>
        </p> -->
      <!-- </template> -->

      <div class="main_table">
        <div class="table_title" style="padding-bottom:10px;">
          <p style="margin:10px 0 5px 0;">任务信息</p>
          <p v-if="fzrName" style="color:rgb(0,132,255);font-size:12px;margin:0">负责人：{{fzrName}}</p>
        </div>
        <el-table :data="[detailDat]" v-if="type==0" border style="width: 100%">
          <el-table-column prop="type" label="加工类型"></el-table-column>
          <el-table-column prop="resultformat" label="成果格式"></el-table-column>
          <el-table-column prop="totalmoney" label="总酬劳" :formatter="(row)=>row.totalmoney+'知识币'"></el-table-column>
          <el-table-column prop="fzr_totalmoney" label="负责人酬劳" :formatter="(row)=>row.fzr_totalmoney+'知识币'"></el-table-column>
          <el-table-column prop="totaldays" label="加工工期" :formatter="(row)=>row.totaldays+'天'"></el-table-column>
          <el-table-column prop="isPaging" label="分页" :formatter="clPaging"></el-table-column>
          <el-table-column prop="isFullTextEntry" label="全文录入" :formatter="clFullTextEntry"></el-table-column>
          <el-table-column prop="checkWay" label="校对" :formatter="setCheckWay"></el-table-column>
          <el-table-column prop="userCreditLevel" label="信用等级" :formatter="clUserCreditLevel"></el-table-column>
          <el-table-column prop="errorRate" label="录入错误率" :formatter="(row)=>row.errorRate+'%'"></el-table-column>
        </el-table>
        <el-table :data="[detailDat]" v-if="type==1" border style="width: 100%">
          <el-table-column prop="type" label="加工类型" :formatter="jgType"></el-table-column>
          <el-table-column prop="resultformat" label="成果格式"></el-table-column>
          <el-table-column prop="totalmoney" label="总酬劳" :formatter="(row)=>row.totalmoney+'知识币'"></el-table-column>
          <el-table-column prop="fzr_totalmoney" label="负责人酬劳" :formatter="(row)=>row.fzr_totalmoney+'知识币'"></el-table-column>
          <el-table-column prop="totaldays" label="加工工期" :formatter="(row)=>row.totaldays+'天'"></el-table-column>
          <el-table-column prop="userCreditLevel" label="信用等级" :formatter="clUserCreditLevel"></el-table-column>
        </el-table>
        <el-table :data="[detailDat]" v-if="type==2" border style="width: 100%">
          <el-table-column prop="resultformat" label="成果格式"></el-table-column>
          <el-table-column prop="totalmoney" label="总酬劳" :formatter="(row)=>row.totalmoney+'知识币'"></el-table-column>
          <el-table-column prop="fzr_totalmoney" label="负责人酬劳" :formatter="(row)=>row.fzr_totalmoney+'知识币'"></el-table-column>
          <el-table-column prop="totaldays" label="加工工期" :formatter="(row)=>row.totaldays+'天'"></el-table-column>
          <el-table-column prop="userCreditLevel" label="信用等级" :formatter="clUserCreditLevel"></el-table-column>
        </el-table>
      </div>

      <div class="main_table" v-if="childList.length>0">
        <div class="table_title" style="padding:10px 0;">
          任务划分信息
        </div>
        <el-table :data="childList" border style="width: 100%">
          <el-table-column prop="childTaskName" label="子任务名称"></el-table-column>
          <!-- <el-table-column prop="jgUserName" label="加工者" v-if="type==0"
           :formatter="(row)=>row.jgUserName||'未被领取'"></el-table-column> -->
          <el-table-column prop="childTaskMoney" label="子任务酬劳" :formatter="(row)=>row.childTaskMoney+'知识币'"></el-table-column>
          <el-table-column prop="childTaskDay" label="加工工期" v-if="type==0"
           :formatter="(row)=>row.childTaskDay+'天'"></el-table-column>
          <el-table-column prop="isPaging" label="操作" v-if="isShowLqBtn">
            <el-button type="primary" slot-scope="scope" v-if="!scope.row.jgUserId" @click="tapGetChildTask(scope)">领取</el-button>
            <el-button type="primary" v-else disabled>已领取</el-button>
          </el-table-column>
          <el-table-column prop="isPaging" label="操作" v-else>
            <el-button type="primary" slot-scope="scope"  disabled v-if="!scope.row.jgUserId">未领取</el-button>
            <el-button type="primary" v-else disabled>已领取</el-button>
          </el-table-column>
        </el-table>
      </div>

      <div class="main_btn" v-if="isShowZm">
        <div class="btn" @click="tapRecruit">
          招募负责人
        </div>
      </div>

      <div class="main_btn" v-if="isShowZmFzr">
        <el-button type="primary" disabled>已参加招募</el-button>
      </div>
    </div>

    <!-- 领取子任务合约 -->
    <div class="keepoOut" v-if="isShowKeep" @click.stop="isShowKeep=false">
      <div class="keepDiv" @click.stop>
        <div class="keepBack" @click.stop="isShowKeep=false">
          <i class="el-icon-close"></i>
        </div>
        <p class="keepTitle">知识加工合约</p>
        <div style="width:100%;height:400px;overflow: hidden;">
          <div style="color:#000;width: calc(100% + 17px);height:400px; overflow-y:auto;" v-html="hyContent">
          </div>
        </div>
        <div class="keepTitle2">
          <span>
            甲方:{{lqFzrName}}
          </span>
          <span>
            乙方:{{userName}}
          </span>
        </div>
        <el-button type="primary" class="keepBtn" @click.stop="tapConsent">同意</el-button>
      </div>
    </div>


    <!-- 招募负责人合约 -->
    <div class="keepoOut" v-if="isShowTaskKeep" @click.stop="isShowTaskKeep=false">
      <div class="keepDiv" @click.stop>
        <div class="keepBack" @click.stop="isShowTaskKeep=false">
          <i class="el-icon-close"></i>
        </div>
        <p class="keepTitle">知识加工合约</p>
        <div style="width:100%;height:400px;overflow: hidden;">
          <div style="color:#000;width: calc(100% + 17px);height:400px; overflow-y:auto;" v-html="hyContent">
          </div>
        </div>
        <!-- <p class="keepTitle1" style="margin-top:30px">
          是买方同意在一段指定时间之后按特定价格收某种资产，卖方同意在一段指定时间之后按特定价格交付某种资产的协议。
          双方同意将来交易时使用的价格称期货格。双方将来必须进行交易的指定日期称为结算日或交割日。双方同意交换的资产称为“标的。 
        </p>
        <p class="keepTitle1">
          是买方同意在一段指定时间之后按特定价格收某种资产，卖方同意在一段指定时间之后按特定价格交付某种资产的协议。
          双方同意将来交易时使用的价格称期货格。双方将来必须进行交易的指定日期称为结算日或交割日。双方同意交换的资产称为“标的。 
          合约生效时间：任务启动时间合约截止时间--任务结束提交时间. 
        </p> -->
        <div class="keepTitle2">
          <span>
            甲方:{{furName}}
          </span>
          <span>
            乙方:{{userName}}
          </span>
        </div>
        <el-button type="primary" class="keepBtn" @click.stop="tapTaskConsent">同意</el-button>
      </div>
    </div>
  </div>
</template> 

<script>
import { mapMutations } from "vuex";
import ArticleNav from "@/components/article-nav/index.vue";
import { QUERYED,UPDATES,INSERT,DELETE } from "@/services/dao.js";
import {post} from "@/services/http.js";
import api from "@/dev/api.ts";
import {dateType} from "@/js/common";
export default {
  components: {
    "V-ArticleNav": ArticleNav
  }, 
  data() {
    return {
      navList:[{word:''}],  //导航 
      isNotBack:'',   //是否返回拦截
      userid:'',  //用户id
      userName:'',  //用户名称
      dateType,   //时间格式化
      type:0,  //0：纸质化加工 1 ：数字化产品加工 2 词条
      id:0,  //主任务id

      detailDat: {},  //主任务数据

      showChild:false,  //子任务显示 
      childList:[],   //子任务列表

      //子任务领取合约
      isShowKeep:false,  //是否显示弹窗
      childId:'',  //子任务id
      childName:'',  //子任务id
      fzrName:'',  //负责人名称
      lqFzrName:'',  //领取子任务时负责人名称
      childStatus:-1,   //当前子任务状态
      updateCyInfo:null,   //更换成员时的信息
      hyContent:'',  //合约内容

      //招募负责人合约
      isShowTaskKeep:false,
      furName:'',  //发布人姓名（甲方）
    }
  },

  //页面离开前拦截路由
  beforeRouteLeave (to, from, next) {
    if(to.path=='/ZSmachining'||!this.isNotBack){
      next()
    }else{ 
      next('/ZSmachining')
    }
  },

  created(){
    let {id,type,token,isNotBack} = this.$route.query
    this.userid = this.$store.state.userinfo.id;  
    this.userName = this.$store.state.userinfo.name
    this.type = type
    this.id = id
    this.isNotBack = isNotBack
    if(token){
      this.getUserLogin(token)
    }else{
      this.getData(id,type)
    }
  },
  
  computed:{
    //是否显示招募按钮
    isShowZm(){
      if(this.userid == this.detailDat.userid)return false   //为发布人时不显示按钮
      if(this.detailDat.jgFzrAndTaskId) return false   //有已确认负责人时不显示
      if(this.showChild)return false  //负责人划分任务后不显示
      return true
    },

    //显示是否已参加招募按钮
    isShowZmFzr(){
      if(this.userid == this.detailDat.userid)return false   //为发布人时不显示按钮
      if(this.detailDat.jgFzrAndTaskId) return false   //有已确认发布人时不显示
      if(!this.showChild)return false  //负责人划分任务后不显示
      return true
    },

    //是否显示领取按钮
    isShowLqBtn(){
      if(this.userid == this.detailDat.userid)return false   //为发布人时不显示按钮
      if(this.userid == this.detailDat.jgFzrAndTaskId) return false   //负责人布人不显示
      if(this.showChild)return false  //负责人划分任务后不显示
      return true
    },

  },
  methods: {
    ...mapMutations(["changeLogin"]),
    ...mapMutations(["changeLoginUser"]),
    //如果有token，则重新获取用户数据
    getUserLogin(token){
      let url = '/user/check?token='+token
       post(url,{}).then(res=>{
        if(res.success){
          // 报错用户token及用户数据
          this.changeLogin({ Authorization: token });
          this.changeLoginUser({ userinfo: res.data.data });
          this.getData(this.id,this.type)
        }
      }) 
    },

    //发布任务类型
    taskShowType(){
      switch (this.type) {
        case '0': return '数字纸质化加工'
        case '1': return '数字产品加工'
        case '2': return '词条加工'
      }
    },

    //处理加工类型
    jgType(row){
      if(this.type==1){
        switch (row.type) {
          case '1': return '论文'
          case '2': return '图书'
          case '3': return '科技报告'
          case '4': return '广告设计'
          case '5': return '软件类说明书'
          case '6': return 'LOGO制作'
          default:return ""
        }
      }
    },

    //处理名称显示
    cuName(type){
      switch (type) {
        case '1':return '作品名称'
        case '2':return '作品名称'
        case '3':return '名称'
        case '4':return '系统名称'
        case '5':return '广告名称'
        case '6':return 'LOGO名称'
        default:return ""
      }
    },

    //处理分页
    clPaging(row){
      switch (row.isPaging) {
        case 1:
            return '是'
        case 2:
            return '否'
        default:break
      }
    },

    //处理全文录入
    clFullTextEntry(row){
      switch (row.isFullTextEntry) {
        case 1:
            return '是'
        case 2:
            return '否'
        default:break
      }
    },

    //处理信用等级
    clUserCreditLevel(row){
      let num = row.userCreditLevel
      if(num==3)return '21级以上'
      if(num==2)return '11~20级'
      if(num==1)return '0~10级'
      return '无要求'
    },

    //下载文件
    tapDownload(url){
      let url1 = api.LoginURL.concat('/resources/trading'+url.replace('/home/uploadFile','/upload'))
      const link = document.createElement("a"); //创建a标签
      let arr = url1.split('/')
      link.download = arr[arr.length-1]; //a标签添加属性
      link.href = url1;
      link.click(); //执行下载
      console.log(123);
    },

    //处理路径
    cuPath(url){
      if(url){
        let arr = url.split('/')
        return arr[arr.length-1]
      }
    },
    
    //警告弹窗
    msgWarn(message,type){
      this.$message({
        message,type: type||'warning'
      });
    },

    //获取数据
    async getData(id,type){
      let tableStr = ""
      //纸质化加工表
      if(type==0){
        tableStr ="jgPeper(where:{id:{_eq:"+id+"}}) {"
        +"id taskname userid username describe type zipPath resultformat isPaging isFullTextEntry checkWay processingFiles"
        +" customEntry errorRate totaldays userCreditLevel totalmoney fzr_totalmoney jgFzrAndTaskId status issueTime tasktype}"

      }else if(type == 1){
        //数字产品发布表
        tableStr ="jgDigitalPro(where:{id:{_eq:"+id+"}}) {"
        +"id taskname userid username describe type resultformat totaldays totalmoney fzr_totalmoney jgFzrAndTaskId status"
        +" title subject keyword summary classification product style textRequirement imageRequirement requirement language"
        +" framwork environment color issueTime startTimes endTime tasktype}"
      }else if(type==2){
        //词条发布表
        tableStr ="jgEntryPro(where:{id:{_eq:"+id+"}}) {"
        +"id taskname userid username describe type resultformat fujianPath totaldays totalmoney fzr_totalmoney jgFzrAndTaskId"
        +" status issueTime startTimes endTime tasktype}"
      }
      let res = await QUERYED("post","",tableStr);
      if(res.success){
        let arr = null
        if(type==0){
          arr =  res.data.jgPeper[0]
        }else if(type==1){
          arr =  res.data.jgDigitalPro[0]
        }else if(type==2){
          arr =  res.data.jgEntryPro[0]
        }
        this.$set(this.navList[0],'word',arr.taskname)
        this.detailDat = arr

        //查询是否已近有负责人
        if(arr.jgFzrAndTaskId){
          this.getChildTiskList(arr.jgFzrAndTaskId,arr.id)
        }else{
          //查询是否有招募
          if(!this.userid)return
          let getJgFzrAndTask ="jgFzrAndTask(where:{fzrId:{_eq:"+this.userid+"},jgPeperid:{_eq:"+id+"}}) {id fzrId jgPeperid}"
          let getData = await QUERYED("post","",getJgFzrAndTask);
          if(getData.success){
            let arrList = getData.data.jgFzrAndTask
            if(arrList.length>0){
              this.showChild = true
              this.getChildTiskList(arrList[0].fzrId,arrList[0].jgPeperid)
            }
          }
        }
      }
    },

    //获取子任务列表
    async getChildTiskList(fzrId,taskId,isAll){
      let getDataTable ="jgChildTask(where:{fzrId:{_eq:"+fzrId+"},taskId:{_eq:"+taskId+"}}) {id taskType taskId status"
      +" taskName fzrId fzrName childTaskId childTaskName childTaskDescribe childTaskPaging childImgpath childTaskMoney"
      +" childTaskDay jgUserId jgUserName}"
      let getData = await QUERYED("post","",getDataTable);
      if(getData.success){
        this.childList = getData.data.jgChildTask
        if(this.childList.length>0){
          this.fzrName = this.childList[0].fzrName
        }
        //判断子任务是否被领取完
        if(isAll){
          for(let item of getData.data.jgChildTask){
            if(!item.jgUserId){
              return
            }
          }
          this.startTask()
        }
      }
    },

    setCheckWay(){
      return '一录一校'
    },

    //点击招募负责人时
    async tapRecruit(){
      let userid = this.$store.state.userinfo.id;   
      if(!userid){
        this.$alert('请登录', '提示', {
          confirmButtonText: '确定',
          callback: () => {
            this.$router.push({path:'/login'})
          }
        });
        return
      }  
      let {type,id,msgWarn} = this
      let tableStr = ''
      let data = ''
      if(type==0){
        tableStr ="jgPeper(where:{id:{_eq:"+id+"}}) { jgFzrAndTaskId }"
      }else if(type == 1){
        //数字产品发布表
        tableStr ="jgDigitalPro(where:{id:{_eq:"+id+"}}) { jgFzrAndTaskId }"
      }else if(type==2){
        //词条发布表
        tableStr ="jgEntryPro(where:{id:{_eq:"+id+"}}) {jgFzrAndTaskId}"
      }
      let res = await QUERYED("post","",tableStr);
      if(!res.success){
        msgWarn('获取数据失败，请重试')
        return
      }
      if(type==0){
        data = res.data.jgPeper[0]
      }else if(type == 1){
        data = res.data.jgDigitalPro[0]
      }else if(type==2){
       data = res.data.jgEntryPro[0]
      }
      if(data&&data.jgFzrAndTaskId){
        this.getData(id,type)
        msgWarn('当前任务负责人已确认')
        return
      }

      let zshy = await QUERYED("post","","zshy(where:{type:{_eq:"+2+"}}) {content}");
      if(!zshy.success){
        msgWarn('获取数据失败，请重试')
        return
      }
      this.hyContent = zshy.data.zshy[0].content
      this.furName = this.detailDat.username
      this.isShowTaskKeep = true
    },

    //招募负责人点击同意时
    tapTaskConsent(){
      let type = this.type
      if(type==0){
        this.$root.Hub.$once('releaseSuccess',res=>{
          if(res)this.getData(this.id,this.type)
        })
        this.$router.push({path:'/taskDivide?id='+this.id+"&type=0"})
      }else if(type==1){
        this.setFigureProduct()
      }else if(type==2){
        this.setWandingProduct()
      }
      this.isShowTaskKeep = false
    },

    //点击领取任务时
    async tapGetChildTask(scope){
      let {userid,type,userName,msgWarn} = this
      let {id,childTaskName,fzrName,taskId,status,jgUserId} = scope.row
      let getDataTable = "jgChildTask(where:{jgUserId:{_eq:"+userid+"},taskId:{_eq:"+taskId+"},_or:[{status:{_gte:\"1\"}},{status:{_gte:\"2\"}},{status:{_gte:\"3\"}},{status:{_gte:\"4\"}}]}) {id jgUserId jgUserName}"
      let getData = await QUERYED("post","",getDataTable);
      if(getData.success){
        if(getData.data.jgChildTask.length>0){
          msgWarn('你已有其他子任务正在进行中')
          return
        }
      }else{
        msgWarn('获取数据失败,请重试')
        return
      }

      if(type==0){
        let userCreditLevel = this.detailDat.userCreditLevel
        let getData = await QUERYED("post","","User(where:{id:{_eq:"+userid+"}}) {creditRating}");
        if(getData.success){
          let leave = getData.data.User[0].creditRating
          if(userCreditLevel==3){
            if(leave<21){
              msgWarn('您的信用等级未达到要求,不能领取')
              return
            }
          }
          if(userCreditLevel==2){
            if(leave<11){
              msgWarn('当前任务需要信用等级为11级以上， 你的信用等级为'+getData+',不能领取')
              return
            }
          }
        }else{
          msgWarn('获取数据失败,请重试')
          return
        }
      }

      //查询子任务是否可以领取
      let isdraw = await QUERYED("post","","jgChildTask(where:{id:{_eq:"+id+"}}) {status}");
      let statusType = -1
      if(!isdraw.success){
        msgWarn('获取数据失败,请重试')
        return
      }else{
        statusType = isdraw.data.jgChildTask[0].status
        if(statusType!=0&&statusType!=6&&statusType!=7){
          this.$set(scope.row,'status',statusType)
          msgWarn('当前子任务已被领取')
          return
        }
        if(statusType==6||statusType==7){
          this.updateCyInfo = {
            taskId:taskId,  //任务id
            detailTaskId:id,  //任务id
            oldUserId:jgUserId,   //更换前的用户id
            userName:userName,  //更换后的用户名
            userId:userid   //更换后的id
          }
        }
      }
      let zshy = await QUERYED("post","","zshy(where:{type:{_eq:"+3+"}}) {content}");
      if(!zshy.success){
        msgWarn('获取数据失败，请重试')
        return
      }
      this.hyContent = zshy.data.zshy[0].content
      this.childStatus = statusType
      this.childId = id
      this.childName = childTaskName
      this.lqFzrName = fzrName
      this.isShowKeep = true
    },

    //调用课题二 ——>数字成品任务划分
    setFigureProduct(){
      let token = localStorage.getItem('Authorization')
      let targetUrl = 'http://www.izhihui.net/zz/#/home/task/index?id='+this.id
      let url = 'http://www.izhihui.net/api/user/add/token?token='+token+'&targetUrl='+encodeURIComponent(targetUrl)
      let createA = document.createElement('a')
      console.log(url,123456);
      createA.href = url
      createA.click()
    },

    //调用课题三 ——>数字成品任务划分
    setWandingProduct(){
      let token = localStorage.getItem('Authorization')
      let url = 'http://www.izhihui.net/eybak/#/assignTask?taskId='+this.id+'&token='+token+"&fbzId="+this.detailDat.userid
      // let url = 'http://106.2.224.58:8037/assignTask?taskId='+this.id+'&token='+token+"&fbzId="+this.detailDat.userid
      // let url = 'http://101.200.34.92/assignTask?taskId='+this.id+'&token='+token+"&fbzId="+this.detailDat.userid
      let createA = document.createElement('a')
      createA.href = url
      createA.click()
    },

    //点击同意时
    async tapConsent(){
      console.log(this.hyContent,123);
      let content = this.hyContent.replace(/"/g,'\\"')
      //添加合约
      let strRelease = "aId:"+this.detailDat.jgFzrAndTaskId+",aName:\""+this.fzrName+"\",bId:"+this.userid+",bName:\""
        +this.userName+"\",content:\""+content+"\",startTime:\""+new Date()*1+"\",type:3,taskId:"+this.childId+",taskName:\""
      +this.childName+"\""
      let addContract = await INSERT(
        "POST","",
        'insert_jgContract(objects:{'+ strRelease +'}) {affected_rows returning {id}}'
      )
      if(!addContract.success){
        this.msgWarn('领取子任务失败,请重试')
        return
      }
      let affirm = await UPDATES(
        "post","",
        "update_jgChildTask(_set: {jgUserId:"+this.userid+",jgUserName:\""+this.userName+"\",status:\"1\",drawTime:\""+new Date()*1+"\"}, where:{id:{_eq: "+this.childId+"}}) { affected_rows }"
      )
      if(!affirm.success){
        await DELETE(
          "post","",
          'delete_jgContract(where: {id:{_eq:'+addContract.data.insert_jgContract.returning[0].id+'}}) {affected_rows}'
        )
        this.msgWarn('领取子任务失败,请重试')
        return
      }else{
        let {id,jgFzrAndTaskId} = this.detailDat
        this.isShowKeep = false
        if(this.childStatus==6||this.childStatus==7){
          this.getChildTiskList(jgFzrAndTaskId,id)  //重新获取子任务
          this.updateCy(this.updateCyInfo)
        }else{
          this.getChildTiskList(jgFzrAndTaskId,id,true)  //重新获取子任务
        }
        this.$alert('恭喜您领取成功！请前往个人中心-加工进度-我的加工查看', '提示', {
          confirmButtonText: '确定',
          callback: () => {
            this.$router.push({path:'/made'})
          }
        });
      }
    },

    //修改成员
    updateCy(obj){
      post('/common/changeTask',obj).then(res=>{
        console.log('修改成员调用');
        console.log(res);
        if(res.success){
        }
      }) 
    },

    //启动任务
    startTask(){
      let type = this.type
      if(type==0){
        this.startPeperTask()
      }else if(type==1){
        this.startDigitizationTask()
      }else{
        this.startEntryTask()
      }
    },

    //纸质化加工启动
    async startPeperTask(){
      let {id,totaldays,status} = this.detailDat
      let staetTime = new Date()*1
      let endTime = staetTime+totaldays*86400000
      console.log('启动任务');
      if(status==0){  //如果主任务未启动则启动主任务
        console.log('启动主任务');
        let jgPeper = await UPDATES(
          "post","",
          "update_jgPeper(_set: {status:2,startTimes:"+staetTime+",endTime:"+endTime+"}, where:{id:{_eq: "+id+"}}) { affected_rows }"
        )
        //如果主任务启动后通知相关成员
        if(jgPeper.success){
          console.log('启动主任务成功');
          this.informAllMember() 
        }
      }
      this.startPeperChildTask(this.childList,0)
    },

    //数字化加工启动
    startDigitizationTask(){
      let {id,userid,username,taskname,describe,type,totalmoney,fzr_totalmoney,totaldays,subject,summary,keyword,
       classification,product,style,textRequirement,imageRequirement,requirement,language,framwork,environment,color,
      jgFzrAndTaskId} = this.detailDat
      let obj = {
        taskId:id,  //主任务id
        taskName:taskname,   //广告, 
        createId:userid,    //发布人id
        createName:username, // 发布者姓名
        endTime: new Date()*1 + totaldays*86400000,  //结束时间
        reward:totalmoney*1-fzr_totalmoney*1,  //总酬劳 - 负责人酬劳
        type:type,   //任务类型
        taskInfo:describe,  // "任务描述信息"
        detailsTask:[{
          detailsTaskRole: 8,    //固定值
          userId: jgFzrAndTaskId,  //负责人id
          userName: this.fzrName   //负责人名称
        }]
      }
      switch (type) {
        case '1':
          obj.subject = subject   //领域（课题五指定的九个领域
          obj.keyword = keyword   //关键词
          obj.summary = summary   //摘要
          obj.classification = classification   //论文分类号
          break;
        case '2':
          obj.subject = subject   //领域（课题五指定的九个领域
          obj.summary = summary   //摘要
          break;
        case '3':
          obj.subject = subject   //领域（课题五指定的九个领域
          obj.keyword = keyword   //关键词
          obj.summary = summary   //摘要
          break;
        case '4':
          obj.product = product    //广告涉及产品
          obj.style = style   //广告风格要求
          obj.textRequirement = textRequirement   //广告文案要求
          obj.imageRequirement = imageRequirement   //广告图片要求
          obj.imageRequirement = imageRequirement   //广告图片要求
          break;
        case '5':
          obj.requirement = requirement    //系统功能要求
          obj.language = language   //编程语言
          obj.framwork = framwork   //编程框架
          obj.environment  = environment    //数据库及运行环境
          break;
        case '6':
          obj.requirement = requirement    //包含元素要求
          obj.color = color   //颜色要求
          obj.textRequirement = textRequirement   //文字要求
          break;
      }
      for(let item of this.childList){
        obj.detailsTask.push({
          detailsTaskRole: 4,   //固定值
          detailsTaskContent:item.childTaskDescribe,  // 子任务描述
          detailsTaskId: item.id,  //子任务id
          detailsTaskMoney: item.childTaskMoney,   //子任务酬劳
          detailsTaskTitle: item.fzrName,  //负责人名称
          userId: item.jgUserId,  //加工者id
          userName: item.jgUserName  //加工者名称
        })
      }

      let then = this
      async function setStatus(){
        let staetTime = new Date()*1
        let endTime = staetTime+totaldays*86400000
        let jgDigitalPro = await UPDATES(
        "post","",
          "update_jgDigitalPro(_set: {status:2,startTimes:"+staetTime+",endTime:"+endTime+"}, where:{id:{_eq: "+id+"}}) { affected_rows }"
        )
        //如果主任务启动后通知相关成员
        if(jgDigitalPro.success){
          then.startPeperChildTask(then.childList,0)
          then.informAllMember() 
        }
      }

      post('/common/startDigitalProTask',obj).then(res=>{
        if(res.success){
          setStatus()
        }
      }) 

    },

    //启动词条加工
     startEntryTask(){
      let {id,resultformat,totaldays} = this.detailDat
      let obj = {
        taskId:id,   //任务id
        resultFileType:resultformat,  //成果格式
        member:[],  //子任务
      }
      for(let item of this.childList){
        let {childTaskName,jgUserId,childTaskId} = item
        let role = childTaskName.indexOf('审核任务')==0?3:2
        obj.member.push({
          userId:jgUserId,
          role:role,
          subTaskId:[childTaskId]
        })
      }
      obj.member.push({
        userId:this.detailDat.jgFzrAndTaskId,
        role:1,
        subTaskId:[]
      })


      let then = this
      async function setStatus(){
        let staetTime = new Date()*1
        let endTime = staetTime+totaldays*86400000
        let jgEntryPro = await UPDATES(
        "post","",
          "update_jgEntryPro(_set: {status:2,startTimes:"+staetTime+",endTime:"+endTime+"}, where:{id:{_eq: "+id+"}}) { affected_rows }"
        )
        //如果主任务启动后通知相关成员
        if(jgEntryPro.success){
          then.startPeperChildTask(then.childList,0)
          then.informAllMember() 
        }
      }

      post('/common/startEntryTask',obj).then(res=>{
        if(res.success){
          setStatus()
        }
      }) 
    },

    //修改子任务启动状态
    async startPeperChildTask(childList,num){
      let {id,childTaskDay,status,taskId,taskName,childTaskName,jgUserId,totaldays} = childList[num]
      let staetTime = new Date()*1
      let endTime = null
      if(totaldays&&totaldays>0){
        endTime = childTaskDay+totaldays*86400000
      }
      if(status=='0'||status=='1'||status=='6'||status=='7'){  //判断子任务是否启动
        let childTsak = await UPDATES(
          "post","",
          "update_jgChildTask(_set: {status:\"2\",startTimes:\""+staetTime+"\",endTime:"+
          (endTime?("\""+endTime+"\""):endTime)
          +"}, where:{id:{_eq: "+id+"}}) { affected_rows }"
        )
        if(childTsak.success){
          //通知加工者
          let tzfbr = "messageType:1,taskTime:"+staetTime+",taskName:\""+childTaskName+"\",taskType:2,taskId:"+id
          +",taskFatherId:"+taskId+",userId:"+jgUserId+",userType:3,taskTypeTow:0"
          await INSERT(
            "POST","",
            'insert_jgMessage(objects:{'+ tzfbr +'}) {affected_rows returning {id}}'
          )

          //更改子任务合约表结束日期
          await UPDATES(
            "post","",
            "update_jgContract(_set: {endTime:\""+(staetTime+this.detailDat.totaldays*86400000)
            +"\"}, where:{taskId:{_eq: "+id+"},type:{_eq:3}}) { affected_rows }"
          )

        }
      }
      num++
      if(num<childList.length)this.startPeperChildTask(childList,num)
    },

    //通知主任务相关成员
    async informAllMember(){
      let {id,taskname,userid,jgFzrAndTaskId,totaldays} = this.detailDat
      let taskTime = new Date()*1
      
      //通知发布人
      let tzfbr = "messageType:1,taskTime:"+taskTime+",taskName:\""+taskname+"\",taskType:2,taskId:"+id
      +",taskFatherId:"+id+",userId:"+userid+",userType:1,taskTypeTow:0"
      await INSERT(
        "POST","",
        'insert_jgMessage(objects:{'+ tzfbr +'}) {affected_rows returning {id}}'
      )

      //通知负责人
      let tzfzr = "messageType:1,taskTime:"+taskTime+",taskName:\""+taskname+"\",taskType:2,taskId:"+id
      +",taskFatherId:"+id+",userId:"+jgFzrAndTaskId+",userType:2,taskTypeTow:0"
      await INSERT(
        "POST","",
        'insert_jgMessage(objects:{'+ tzfzr +'}) {affected_rows returning {id}}'
      )

      //更改主任务合约表结束日期
      let staetTime = new Date()*1
      await UPDATES(
        "post","",
        "update_jgContract(_set: {endTime:\""+(staetTime+totaldays*86400000)
        +"\"}, where:{taskId:{_eq: "+id+"},type:{_eq:2}}) { affected_rows }"
      )
    }
  }
};
</script>

<style scoped>
.container {
  /* text-align: center; */
  background-color: #EEEEEE;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.headClass {
  width: 1090px;
  height: 66px;
  line-height: 66px;
  text-align: left;
}
.gWbc{
  width: 1090px;
  background-color: #fff;
}
.title{
  min-height: 72px;
  padding-top: 45px;
  text-align: center;
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 3px solid #eee;
}
.title_div{
  width: 100%;
  padding:28px 0;
  font-size: 14px;
  font-weight: 500;
  color:#000;
}
.title_div>span>span{
  color: #0084FF;
  
}
.detai_main{
  padding:66px;
  box-sizing: border-box;
}
.miain_p{
  font-size: 14px;
  color:#000;
}
.miain_p>span{
  font-weight: bold;
}
.main_table{
  width: 100%;
  margin-top: 40px;
}
.main_table>>>.cell{
  font-size: 14px;
  /* font-weight: bold; */
  color: #000;
}
.main_table>>>th.cell{
  font-size: 14px;
  font-weight: bold;
  color: #000;
}
.table_title{
  width: 100%;
  /* padding:22px 0; */
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  border:2px solid #eee;
  border-bottom-width: 0;
  box-sizing: border-box;
}
.main_btn{
  width: 100%;
  padding-top: 40px;
  display: flex;
  justify-content: center;
}
.btn{
  width: 130px;
  height: 36px;
  /* margin:0 auto; */
  background: #0084FF;
  border: 1px solid #0084FF;
  border-radius: 5px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFEFE;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
}
.btn:hover{
  opacity: 0.9;
  
}
.btn1:hover{
  cursor: no-drop;
}

/* 弹窗 */
.keepoOut{
  width: 100vw;
  height: 100vh;
  
  position: fixed;
  background-color: rgba(0,0,0,0.2);
  top:0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.keepDiv{
  width: 654px;
  padding:20px 70px;
  /* padding-top: 70px;
  padding-bottom: 40px; */
  background-color: #FFFFFF;
  box-sizing: border-box;
  position: relative;
}
.keepTitle{
  font-size: 24px;
  font-weight: bold;
  /* text-decoration: underline; */
  color: #000000;
  text-align: center;
}
.keepTitle1{
  font-size: 14px;
  text-indent: 28px;
  text-align: justify;
}
.keepTitle2{
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  
  
}
.keepTitle2>span{
  font-size: 14px;
  text-indent: 28px;
  font-weight: bold;
}
.keepBtn{
  margin: 0 auto;
  margin-top: 30px;
  display: block;
}
.keepBack{
  width: 21px;
  height: 21px;
  border:2px solid #999;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right:10px;
  cursor: pointer;
}
.keepBack:hover{
  opacity: 0.8;
}
.keepBack>i{
  font-size: 30px;
  color:#999;
}

</style>
